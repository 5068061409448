import React, { Component } from 'react'

declare global {
  interface Window {
    TradingView: any
  }
}

export default class TradingViewWidget extends Component {
  myRef: React.RefObject<HTMLDivElement>
  constructor(props: Readonly<{}>) {
    super(props)
    this.myRef = React.createRef()
  }

  componentDidMount() {
    const script = document.createElement('script')
    script.id = 'tradingview-widget-script'
    script.type = 'text/javascript'
    script.async = true

    script.src = 'https://s3.tradingview.com/tv.js'
    script.onload = this.initWidget

    this.myRef.current?.appendChild(script)
  }

  initWidget = () => {
    /* global TradingView */
    new window.TradingView.widget({
      autosize: true,
      symbol: 'IDX:JAST',
      interval: 'D',
      timezone: 'Etc/UTC',
      theme: 'light',
      style: '1',
      locale: 'id',
      toolbar_bg: '#f1f3f6',
      enable_publishing: false,
      allow_symbol_change: false,
      hide_top_toolbar: true,
      save_image: false,
      container_id: 'tradingview_2671e',
    })
  }

  render() {
    return (
      <div className="tradingview-widget-container" ref={this.myRef}>
        <div id="tradingview_2671e" style={{ height: 500 }}></div>
        {/* <div className="tradingview-widget-copyright">
          <a href="https://id.tradingview.com/symbols/NASDAQ-AAPL/" rel="noopener" target="_blank">
            <span className="blue-text">JAST Chart </span>
          </a>
          oleh TradingView
        </div> */}
      </div>
    )
  }
}
