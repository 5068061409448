import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Hero from '../../components/hero'
import ShareholderChart from '../../components/shareholder-chart'
import TradingViewWidget from '../../components/tradingview-chart'
import SEO from '../../components/seo'

export const query = graphql`
  query StockQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
interface StockPageProps {}

const StockPage = (props: StockPageProps): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = React.useState({
    title: 'Stock Market',
    description: 'Redefining your customer experiences with Jasnita CX solutions.',
  })
  return (
    <>
      <SEO title={content.title} description={content.description} />

      <Hero type="investor">
        <h1 className="uk-heading-small">{content.title}</h1>
        <p>{content.description}</p>
      </Hero>

      <section className="uk-section">
        <div className="uk-container">
          <div uk-grid="">
            <div className="uk-width-1-2@m">
              <h2>Stock Diagram</h2>
              {/* <StockChart data={stockMarket} /> */}
              <TradingViewWidget />
            </div>

            <div className="uk-width-1-2@m">
              <h2>Shareholder Chart</h2>
              <ShareholderChart />
              {/* <h2>Shareholder’s Structure</h2>
              <div className="uk-width-xlarge">
                <table className="uk-table uk-table-divider uk-overflow-auto uk-text-left">
                  <thead>
                    <tr>
                      <th>Shareholder</th>
                      <th>Shares</th>
                      <th>Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>PT Persada Inti Sejahtera</td>
                      <td>438,695,830</td>
                      <td>53.92%</td>
                    </tr>
                    <tr>
                      <td>Kristina Dwi Suryani Pangerapan</td>
                      <td>22.950.000</td>
                      <td>2,82%</td>
                    </tr>
                    <tr>
                      <td>Nurhajanto</td>
                      <td>3.375.000</td>
                      <td>0,41%</td>
                    </tr>
                    <tr>
                      <td>Public</td>
                      <td>348,605,870</td>
                      <td>42.85%</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>813,626,700</td>
                      <td>100%</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="uk-section">
        <div className="uk-container">
          <div className="uk-text-center uk-margin-large-bottom">
            <h2>Shareholder Chart</h2>
            <ShareholderChart />
          </div>
        </div>
      </section> */}
    </>
  )
}

export default StockPage
