import React from 'react'
import { ResponsivePie } from '@nivo/pie'

const data = [
  {
    id: `PT Persada Inti Sejahtera`,
    label: `PT Persada Inti Sejahtera`,
    value: 52.33,
    color: `hsl(203, 70%, 50%)`,
  },
  {
    id: `Kristina Dwi Suryani Pangerapan`,
    label: `Kristina Dwi Suryani Pangerapan`,
    value: 2.12,
    color: `hsl(13, 70%, 50%)`,
  },
  {
    id: `Nurhajanto`,
    label: `Nurhajanto`,
    value: 0.47,
    color: `hsl(13, 70%, 50%)`,
  },
  {
    id: `Public`,
    label: `Public`,
    value: 45.08,
    color: `hsl(201, 70%, 50%)`,
  },
]

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const ShareHolderPieChart = () => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    colors={{ scheme: `nivo` }}
    borderWidth={1}
    borderColor={{ from: `color`, modifiers: [[`darker`, 0.2]] }}
    // radialLabelsSkipAngle={10}
    // radialLabelsTextXOffset={6}
    // radialLabelsTextColor="#333333"
    // radialLabelsLinkOffset={0}
    // radialLabelsLinkDiagonalLength={16}
    // radialLabelsLinkHorizontalLength={24}
    // radialLabelsLinkStrokeWidth={1}
    // radialLabelsLinkColor={{ from: `color` }}
    // slicesLabelsSkipAngle={10}
    // slicesLabelsTextColor="#333333"
    animate={true}
    // motionStiffness={90}
    // motionDamping={15}
    defs={[
      {
        id: `dots`,
        type: `patternDots`,
        background: `inherit`,
        color: `rgba(255, 255, 255, 0.3)`,
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: `lines`,
        type: `patternLines`,
        background: `inherit`,
        color: `rgba(255, 255, 255, 0.3)`,
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: `PT Persada Inti Sejahtera`,
        },
        id: `lines`,
      },
      {
        match: {
          id: `Kristina Dwi Suryani Pangerapan`,
        },
        id: `lines`,
      },
      {
        match: {
          id: `Nurhajanto`,
        },
        id: `lines`,
      },
      {
        match: {
          id: `Public`,
        },
        id: `dots`,
      },
    ]}
    legends={[
      {
        anchor: `bottom-left`,
        direction: `column`,
        translateY: 80,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: `#999`,
        symbolSize: 18,
        symbolShape: `circle`,
        effects: [
          {
            on: `hover`,
            style: {
              itemTextColor: `#000`,
            },
          },
        ],
      },
    ]}
  />
)

export default function ShareholderChart() {
  return (
    <div className="uk-margin uk-height-large">
      <ShareHolderPieChart />
    </div>
  )
}
